import React, { Component } from "react";
import styled from "styled-components";
import { withPage } from "../../Page";
import meal from "../../../content/meal.json";
import * as Widget2 from "../../Components/Widget2";

class CuisinePage extends Component {
  render() {
    return (
      <Wrapper>
        <Widget2.Banner img="/images/banner05.png" css="margin-bottom: 62px;">
          <div className="mist">
            <div className="title">膳食料理</div>
            <div className="slogan">堅持食材安全把關，吃得美味又安心</div>
          </div>
        </Widget2.Banner>

        <Container>
          <div className="intro" style={{ marginBottom: 115 }}>
            <div className="title">品質把關</div>
            <p>
              {
                "由營養師、中醫師與專業證照的月子餐廚師組成「黃金三角」團隊，嚴選每一項食材、中藥材，秉持新鮮多種、多樣，變化每天餐點內容，以符合衛生管理標準的專業認證廚房現煮供應，為您打造健康且健康的月子餐點。"
              }
            </p>
            <Widget2.Row
              justify="center"
              align="center"
              css={`
                @media screen and (max-width: 375px) {
                  flex-wrap: wrap;
                }
              `}
            >
              <div className="feature">
                {"・HACCP食品衛生管理\n・專業營養師設計菜單\n・食材嚴格挑選"}
              </div>
              <div className="feature">
                {"・供應廠商合格認證\n・衛生安全層層把關\n・定期廚房環境消毒"}
              </div>
            </Widget2.Row>
          </div>

          <div className="intro" style={{ marginBottom: 40 }}>
            <div className="title">精美月子餐</div>
            <p>
              {
                "由營養師、中醫師與專業證照的月子餐廚師組成「黃金三角」團隊，嚴選每一項食材、中藥材，秉持新鮮多種、多樣，變化每天餐點內容，以符合衛生管理標準的專業認證廚房現煮供應，為您打造健康且健康的月子餐點。"
              }
            </p>
          </div>

          <div className="images">
            {meal.map((item, i) => (
              <MealItem
                key={"meal-item" + i}
                img={`/images/${item.images}`}
                css={`
                  margin-bottom: 20px;
                `}
              />
            ))}
          </div>
        </Container>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  padding-top: 100px;
`;

const Container = styled.div`
  margin: 0 auto;
  max-width: 1181px;
  padding: 20px;

  & > .intro {
    & > .title {
      margin-bottom: 39px;
      text-align: center;
      font-weight: bold;
      font-size: 30px;
      color: #71c6d0;
      letter-spacing: 3px;
    }

    & > p {
      margin-bottom: 40px;
      text-align: center;
      font-size: 16px;
      color: #101d1f;
      letter-spacing: 1.6px;
    }
  }

  & .feature {
    margin-right: 20px;
    white-space: pre-wrap;
    font-size: 24px;
    color: #71c6d0;
    letter-spacing: 2.4px;

    @media screen and (max-width: 414px) {
      font-size: 14px;
    }

    @media screen and (max-width: 375px) {
      margin-right: 0px;
      min-width: 180px;
    }
  }

  & > .images {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @media screen and (max-width: 768px) {
      justify-content: center;
    }
  }
`;

const MealItem = styled.div`
  max-width: 370px;
  min-height: 265px;
  width: 100%;
  height: 100%;

  background-image: url(${props => props.img});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  ${props => props.css};

  @media screen and (max-width: 1024px) {
    max-width: 482px;
    min-height: 345px;
  }
`;

export default withPage(CuisinePage);
